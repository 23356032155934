import React, { useState } from "react";
import Layout from "../../components/layout/Layout"
import Container from "../../components/layout/Container"
import Type from "../../components/typography/Type"
import { isLoggedIn } from "../../services/auth"
import axios from "axios";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import * as Yup from "yup";
import LoginPrompt from "../../components/app/loginPrompt"
import Input from "../../components/forms/Input"
import Select from "../../components/forms/Select"
import TextArea from "../../components/forms/TextArea"
import Hidden from "../../components/forms/Hidden"


 const formSchema = Yup.object().shape({
  Name: Yup.string().required("Required"), 
});


const NewProject = () => {
  
  
 // Axios server state handling
  const [serverState, setServerState] = useState();
  const handleServerResponse = (ok, msg) => {
	setServerState({ok, msg});
  };
  const handleOnSubmit = (values, actions) => {
	axios({
	  method: "POST",
	  url: "https://formspree.io/f/mdovkypv",
	  data: values,
	})
	  .then(response => {
		actions.setSubmitting(false);
		actions.resetForm();
		handleServerResponse(true, "Thanks!");
		window.location = "/projects"; //This line of code will redirect you once the submission is succeed
	  })
	  .catch(error => {
		actions.setSubmitting(false);
		handleServerResponse(false, error.response.data.error);
	  });
  };
  
  
  

  
  return (
	<Layout>
	<Container>
	
	
	{isLoggedIn() ? (
	  <div>
		<Type type="h2">Add a new project</Type>
		<Formik
		  initialValues={{
			Name: "New project name",
			BlueprintsL1: "New project Blueprint",
			ValueStreams: "New project Value Stream",
			CapabilitiesL1: "New project Capability Map",
			Roadmaps: "New project Horizon Map",
			ProductStories: "New project Product Story Map",
			Journeys: "New project Journey",
			Status: "Live",
		  }}
		  onSubmit={handleOnSubmit}
		  validationSchema={formSchema}
		>
		  {({ values,
			 errors,
			 touched,
			 handleChange,
			 handleBlur,
			 handleSubmit,
			 isSubmitting 
		   }) => (
			
			<Form id="newproject" noValidate >
			  
			  
			  
			  <Field id="BlueprintsL1" name="BlueprintsL1" fieldLabel="BlueprintsL1" component={Hidden} />
			  <Field id="ValueStreams" name="ValueStreams" fieldLabel="ValueStreams" component={Hidden} />
		<Field id="Status" name="Status" fieldLabel="Status" component={Hidden} />
																																   
				   
				   
			  <div className="flex flex-col space-y-12 w-2/3">

				<Field id="Name" name="Name" fieldLabel="Name" component={Input} />





				
				  {/*}
				<FieldArray 
				  name="ResearchObjectives"
				  render={arrayHelpers => (
					<div className="flex flex-col space-y-12">
					  {values.ResearchObjectives && values.ResearchObjectives.length > 0 ? ( 
					   values.ResearchObjectives.map((objective, index) => (
						 <div key={index} className="flex flex-row items-center space-x-12">
							<div className="grow">
							   <Field id={`ResearchObjectives[${index}]`}  fieldLabel={`Objective ${index+1}`}  name={`ResearchObjectives[${index}]`} component={Input}/>
							</div>
							   <button
								 className="w-8 h-8 bg-green/25 flex items-center justify-center rounded-lg text-white text-lg"
								 type="button"
								 onClick={() => arrayHelpers.remove(index)} // remove a goal from the list
								 >x</button>
					
						</div>
						)) 
						
					  ) :
				
					  (
						
					   
						undefined
					  )}       
					  <button 
					  className=" w-fit bg-green/25 py-2 px-4 rounded-lg text-white"
					  type="button" onClick={() => arrayHelpers.push('')}>
					  
						 Add an objective
					   </button> 
					</div>  
				)}
				/> 
		*/}



			  </div>
			  
			  <button type="submit" className="mt-16 bg-green rounded-lg py-4 px-8 text-white border text-lg border-green hover:bg-white hover:text-green font-bold" disabled={isSubmitting}>
				Submit
			  </button>
			  
			  <div>
			 {serverState && (
			   <div className="mt-8">
			   <div className={!serverState.ok ? "errorMsg" : ""}>
				 {serverState.msg}
			   </div>
			   </div>
			 )}
			  </div>
			</Form>
		  )}
		</Formik>
	  </div> 
   ) : (
	   <div>
		 <LoginPrompt/>
	   </div>     
	 )}



   </Container>
	</Layout>
  )
}

export default NewProject

export const Head = () => <title>Framework</title>

